// Components
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import {
  InputAutoComplete,
  IInputAutoCompleteProps,
} from "@/client/components/ui/FormFields/InputAutoComplete";

interface IWrappedFormAutocomplete<ControlT extends FieldValues> extends IInputAutoCompleteProps {
  name: Path<ControlT>;
  control?: Control<ControlT>;
}

export const WrappedFormAutocomplete = <T extends FieldValues>(
  props: IWrappedFormAutocomplete<T>,
) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputAutoComplete {...rest} {...field} ref={field.ref} />}
    />
  );
};

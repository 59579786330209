// Core
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

// Components
import { Flex } from "@/client/components/ui/Flex";
import { Button } from "@/client/components/ui/Button";
import { Text } from "@/client/components/ui/Typography/Text";
import { Image } from "@/client/components/ui/Image";
import { Modal } from "@/client/components/modal";

// Definitions
import { MODALS_BOOK } from "@/client/definitions/modals";

// Hooks
import { useUser } from "@/client/domains/user/hooks/use-user";
import { useModalCtrl } from "@/client/hooks/use-modal-ctrl";

// Utils
import st from "./styles.module.css";
import { Icon } from "@/client/components/ui/Icon";

export type NavbarUserPopoverProps = {
  testId?: string;
  handleVisibleChange?: (visible: boolean) => void;
};

export const NavbarUserPopover = observer((props: NavbarUserPopoverProps) => {
  const { testId = "navbar-user-popover", handleVisibleChange } = props;
  const { t } = useTranslation("common");
  const { user } = useUser();
  const { activeModals, setModalStateByName } = useModalCtrl();

  const avatar = "/assets/images/avatar.svg";

  const onClickLayout = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleLogout = () => {
    handleVisibleChange?.(false);
    setModalStateByName(MODALS_BOOK.signOutConfirm, true);
  };

  const onCloseModal = () => {
    setModalStateByName(MODALS_BOOK.signOutConfirm, false);
  };

  return (
    <div className={st["navbar-user-popover"]} data-testid={testId} onClick={onClickLayout}>
      <Flex justify="space-between" align="flex-start">
        <Flex gap="middle">
          <Image
            src={avatar}
            width={40}
            height={40}
            alt={t("popoverUser.avatarAlt")}
            unoptimized
            priority
          />
          <div className={st["navbar-user-popover__greeting"]}>
            <Text color="gray-900" fontWeight="600" font="second" tag="p">
              {t("popoverUser.greeting")}
            </Text>
            <Text color="gray-900" fontWeight="600" font="second" tag="p">
              {user?.name}
            </Text>
          </div>
        </Flex>
        <Button
          data-testid="navbar-user-popover-btn-logout"
          className={st.button}
          type="link"
          noStyle
          size="small"
          onClick={handleLogout}
        >
          <Icon name="Gear" size="16" />
          <Text size="14">{t("popoverUser.logout")}</Text>
        </Button>
      </Flex>
      <Modal
        modalName={MODALS_BOOK.signOutConfirm}
        isOpen={activeModals[MODALS_BOOK.signOutConfirm]}
        onClose={onCloseModal}
        width={448}
      />
    </div>
  );
});

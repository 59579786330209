"use client";

// Components
import { LayoutAppHeader3Cols } from "@/client/components/layouts/layout-app-header-3cols";
import { NavbarProfile } from "@/client/domains/user/navigation/components/navbar-profile";
import { SearchBar } from "@/client/domains/search/search-bar";

// Utils
import { verifyFeatures } from "@/client/utils/verify-features";

export const HeaderShop = () => {
  const { isSearchBar } = verifyFeatures();
  return (
    <LayoutAppHeader3Cols userMenu={<NavbarProfile />} main={isSearchBar ? <SearchBar /> : null} />
  );
};

import { observer } from "mobx-react-lite";

// Components
import { SearchBarForm } from "../search-bar-form";
import { SearchBarOption } from "../search-bar-option";
import { Modal } from "@/client/components/modal";

// Definitions
import type { SearchAutocompleteOptionType } from "../../types";
import type { SearchBarOptionType } from "../search-bar-form";

// Hooks
import { useModalCtrl } from "@/client/hooks/use-modal-ctrl";
import { useSearchNavigate } from "../../hooks/use-search-navigate";

// Utils
import { MODALS_BOOK } from "@/client/definitions/modals";

export const SearchBar = observer(() => {
  const { activeModals, setModalStateByName } = useModalCtrl();
  const { onSearchNavigate } = useSearchNavigate();

  // TODO: Mock autocomplete options
  const searchKey: string = "";
  const autocompleteOptions: SearchAutocompleteOptionType[] = [
    {
      id: 1,
      type: 2,
      count: null,
      entity: "FILTERASSYOIL",
      keyword: "",
      label: "OEN FILTERASSYOIL",
    },
  ];
  const optionJSX = (
    option: SearchAutocompleteOptionType,
    searchStr: string,
  ): SearchBarOptionType => {
    const { label, keyword, entity, id } = option;
    return {
      id,
      value: label,
      label: <SearchBarOption label={label} keyword={searchStr} />,
      keyword,
      entity,
      type: option.type,
    };
  };

  const options = !searchKey ? [] : autocompleteOptions.map((o) => optionJSX(o, searchKey));

  const handleOpenModal = () => setModalStateByName(MODALS_BOOK.searchBar, true);

  return (
    <>
      <SearchBarForm options={options} onSubmit={onSearchNavigate} onShowModal={handleOpenModal} />
      <Modal
        modalName={MODALS_BOOK.searchBar}
        isOpen={activeModals[MODALS_BOOK.searchBar]}
        onClose={() => setModalStateByName(MODALS_BOOK.searchBar, false)}
        testId={"modal-searchbar-root"}
        closeBtnType="inside"
        width={912}
      />
    </>
  );
});

// Core
import { useCallback } from "react";
import { useRouter } from "next/navigation";

// Definitions
import { type SearchBarType } from "../types";
import { type FormSubmitParams } from "@/client/components/form/form.types";

// Utils
import { routes } from "@/client/definitions/book";

type OnSearchNavigateType = (data: FormSubmitParams<SearchBarType>) => void;

type UseSearchNavigateType = {
  onSearchNavigate: OnSearchNavigateType;
};

export const useSearchNavigate = (): UseSearchNavigateType => {
  const router = useRouter();

  const onSearchNavigate = useCallback<OnSearchNavigateType>(
    (data) => {
      const { keyword, search } = data.values;

      const query = {
        ...(keyword ? { keyword } : { keyword: search }),
        search,
      };
      const qp = new URLSearchParams(query).toString();
      router.push(routes.search + "?" + qp, {
        scroll: false,
      });
    },
    [router],
  );

  return {
    onSearchNavigate,
  };
};

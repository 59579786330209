// Core
import cx from "classnames";

// Definitions
import type { SpinProps as AntdSpinProps } from "antd";

// Components
import { Spin } from "antd";
import { Icon } from "@/client/components/ui/Icon";

// Utils
import st from "./styles.module.css";

declare const SpinColors: ["blue-8", "orange-8", "green-8"];

type LoadingProps = Omit<AntdSpinProps, "size"> & {
  size?: "tiny" | "small" | "medium" | "large" | "extra-large";
  color?: (typeof SpinColors)[number];
  hasSpinning?: boolean;
};

export const Loading = (props: LoadingProps) => {
  const { size = "medium", color = "orange-8", hasSpinning, ...rest } = props;

  const rootStyles = cx(
    st.loading,
    size && {
      [st[`loading-size-${size}`]]: true,
      [st["loading-spinning"]]: Boolean(hasSpinning),
    },
    color && { [st[`loading-color-${color}`]]: true },
  );

  return (
    <div className={rootStyles}>
      <Spin spinning indicator={<Icon name="LoadingOutlined" />} {...rest} />
    </div>
  );
};

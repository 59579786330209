// Core
import { useState, forwardRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { isEmpty } from "ramda";

// Components
import { AutoComplete as AntdAutoComplete, AutoCompleteProps } from "antd";
import { NoData } from "../../NoData";
import { Loading } from "../../Loading";

// Definitions
import type { BaseSelectRef } from "rc-select";
import type { SelectItemAutocomplete } from "../InputSelect/InputSelect.types";

const DEBOUNCE_LATENCY = 300;
const NotFoundContent = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <Loading hasSpinning color="blue-8" />
  ) : (
    <NoData image={NoData.PRESENTED_IMAGE_SIMPLE} />
  );
};

type DefaultAutocompleteOptionType = NonNullable<SelectItemAutocomplete>;

export interface IInputAutoCompleteProps
  extends AutoCompleteProps<void, DefaultAutocompleteOptionType> {
  loading?: boolean;
  delay?: number;
  withNoData?: boolean;
}

export const InputAutoComplete = forwardRef<BaseSelectRef, IInputAutoCompleteProps>(
  (props, ref) => {
    const {
      loading = false,
      withNoData = false,
      delay = DEBOUNCE_LATENCY,
      onSearch,
      ...rest
    } = props;
    const [keyword, setKeyword] = useState("");
    const noDataJSX = withNoData ? <NotFoundContent loading={loading} /> : null;

    const onSearchDebounced = useDebouncedCallback((value: string) => {
      const search = value.trim();
      if (isEmpty(search)) {
        return;
      }
      setKeyword(search);
      search !== keyword && onSearch?.(search);
    }, delay);

    return (
      <AntdAutoComplete
        {...rest}
        onSearch={onSearchDebounced}
        notFoundContent={noDataJSX}
        ref={ref}
        data-testid="autocomplete-input"
      />
    );
  },
);
InputAutoComplete.displayName = "InputAutoComplete";

// Definitions
import type { BadgeProps as AntBadgeProps } from "antd";

// Components
import { Badge as AntBadge } from "antd";

// Utils
import st from "./styles.module.css";

export type BadgeProps = AntBadgeProps;
export const Badge = (props: AntBadgeProps) => {
  return <AntBadge {...props} className={`${st.badge} ${props.className}`} />;
};

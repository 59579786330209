// Core
import { BaseSyntheticEvent, ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

// Components
import { Form } from "@/client/components/ui/Form";
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Button } from "@/client/components/ui/Button";
import { InputForm } from "@/client/components/ui/FormFields/InputForm";
import { Icon } from "@/client/components/ui/Icon";
import { InputBase } from "@/client/components/ui/FormFields/InputBase";
import { WrappedFormAutocomplete } from "@/client/components/form/wrapped-form-autocomplete";
import { Text } from "@/client/components/ui/Typography/Text";

// Definitions
import type { SearchBarType, SearchTypesKeysType } from "../../types";
import type { FormSubmitFn } from "@/client/components/form/form.types";

// Utils
import st from "./styles.module.css";
import { inputConfig, SEARCH_BAR_FORM_KEYS, searchBarForm } from "./config";

export type SearchBarOptionType = {
  id: number;
  label?: ReactNode;
  value: string;
  keyword?: string;
  entity?: string;
  type?: SearchTypesKeysType;
};

type SearchBarFormType = {
  loadingSearch?: boolean;
  initialValues?: SearchBarType | null;
  onSubmit?: FormSubmitFn<SearchBarType>;
  loadingAutocomplete?: boolean;
  options?: SearchBarOptionType[];
  onSearch?: (keyword: string) => void;
  onShowModal?: () => void;
};

export const SearchBarForm = (props: SearchBarFormType) => {
  const {
    loadingSearch = false,
    initialValues = null,
    loadingAutocomplete = false,
    options = [],
    onSubmit,
    onSearch,
    onShowModal,
  } = props;
  const { t } = useTranslation();

  const formProps = useForm<SearchBarType>({
    defaultValues: { ...searchBarForm.shape, ...initialValues },
    resolver: zodResolver(searchBarForm.schema()),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, setError, reset, setValue, watch } = formProps;

  const searchValue = watch(SEARCH_BAR_FORM_KEYS.search);

  useEffect(() => {
    setValue("type", null);
    setValue("entity", "");
    setValue("keyword", "");
  }, [searchValue]);

  const handleSubmitForm = handleSubmit((values: SearchBarType) => {
    if (!values[SEARCH_BAR_FORM_KEYS.search] && !values[SEARCH_BAR_FORM_KEYS.entity]) return;
    onSubmit?.({ values, acts: { setError, reset } });
  });

  const onSubmitForm = () => {
    void (async () => {
      await handleSubmitForm();
    })();
  };

  const handleSelect = (_: void, option: SearchBarOptionType) => {
    option.type && setValue("type", option.type);
    option.entity && setValue("entity", option.entity);
    option.keyword && setValue("keyword", option.keyword);
    onSubmitForm();
  };
  const handleFocus = () => {
    searchValue && !options.length && onSearch?.(searchValue);
  };

  const handleSearch = (keyword: string) => {
    if (!inputConfig[SEARCH_BAR_FORM_KEYS.search].pattern.test(keyword)) {
      return;
    }
    onSearch?.(keyword);
  };

  const handlePressEnter = (e: BaseSyntheticEvent<KeyboardEvent, HTMLInputElement>) => {
    e.preventDefault();
    !!e.currentTarget.value && onSubmitForm();
  };

  return (
    <Form onFinish={onSubmitForm} name="search-bar-form" data-testid="search-bar-form">
      <Row gutter={Row.gutterType.xsm.horizontal}>
        <Col span={21}>
          <InputForm
            type="search-bar"
            id={SEARCH_BAR_FORM_KEYS.search}
            name={SEARCH_BAR_FORM_KEYS.search}
          >
            <WrappedFormAutocomplete
              loading={loadingAutocomplete}
              name={SEARCH_BAR_FORM_KEYS.search}
              control={control}
              options={options}
              onSearch={handleSearch}
              onSelect={handleSelect}
              onFocus={handleFocus}
            >
              <InputBase
                placeholder={t(inputConfig[SEARCH_BAR_FORM_KEYS.search].placeholder)}
                onPressEnter={handlePressEnter}
                name={SEARCH_BAR_FORM_KEYS.search}
                suffix={
                  <Button
                    type="link"
                    noStyle
                    onClick={onShowModal}
                    className={st["search-example"]}
                    icon={<Icon name="InfoCircleFilled" />}
                    iconPosition="end"
                    data-testid="search-bar-modal-open-btn"
                  >
                    <Text size="12" color="gray-4">
                      {t("inputs:searchBar.tooltip")}
                    </Text>
                  </Button>
                }
              />
            </WrappedFormAutocomplete>
          </InputForm>
        </Col>
        <Col span={3}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={loadingSearch}
            icon={<Icon name="SearchOutlined" />}
          >
            {t("buttons:search")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

// Core
import cx from "classnames";

// Definitions
import type { EmptyProps as AntdEmptyProps } from "antd";

// Components
import { Empty as AntdEmpty } from "antd";

// Utils
import st from "./styles.module.css";

type NoDataProps = AntdEmptyProps & {
  testId?: string;
};

export const NoData = ({ testId, description = null, ...rest }: NoDataProps) => {
  const rootStyles = cx(st.empty);

  return (
    <>
      <AntdEmpty className={rootStyles} description={description} data-testid={testId} {...rest} />
    </>
  );
};

NoData.PRESENTED_IMAGE_DEFAULT = AntdEmpty.PRESENTED_IMAGE_DEFAULT;
NoData.PRESENTED_IMAGE_SIMPLE = AntdEmpty.PRESENTED_IMAGE_SIMPLE;

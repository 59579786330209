// Core
import { ReactNode, useCallback } from "react";

// Components
import { Text } from "@/client/components/ui/Typography/Text";

// Utils
import st from "./styles.module.css";
import { escapeStringRegexp } from "@/client/utils/regex";

type SearchBarOptionProps = {
  label: string;
  keyword: string;
};

export const SearchBarOption = ({ label, keyword = "" }: SearchBarOptionProps) => {
  const getHighlightedKeyword = useCallback<() => ReactNode>(() => {
    if (!keyword) {
      return label;
    }
    const regexp = new RegExp(escapeStringRegexp(keyword), "i");
    const firstKeyIndex = label.search(regexp);
    const lastKeyIndex = firstKeyIndex + keyword.length;
    const keyStr = firstKeyIndex >= 0 ? label.substring(firstKeyIndex, lastKeyIndex) : "";
    const firstStr = firstKeyIndex >= 0 ? label.substring(0, firstKeyIndex) : "";
    const lastStr = firstKeyIndex >= 0 ? label.substring(lastKeyIndex, label.length) : label;

    return (
      <>
        {firstStr}
        {keyStr && <span className={st["search-bar-option__keyword"]}>{keyStr}</span>}
        {lastStr}
      </>
    );
  }, [label, keyword]);

  return (
    <div className={st["search-bar-option"]}>
      <Text color="gray-950">{getHighlightedKeyword()}</Text>
    </div>
  );
};

import { ValueOf } from "@/common/types/basic";

export type ScrollToOptionsType = Partial<{ duration: number; delay: number; smooth: boolean }>;

export const scrollTo = (element: string, options?: ScrollToOptionsType) => {
  import("react-scroll/modules/mixins/scroller")
    .then(({ default: scroller }) => {
      const { duration = 500, delay = 500, smooth = true } = options || {};
      return scroller.scrollTo(element, {
        duration,
        delay,
        smooth,
      });
    })
    .catch((err) => {
      throw err;
    });
};

export const SCROLL_NAMES = {
  header: "header",
  footer: "footer",
  basketDeliveryForm: "basketDeliveryForm",
} as const;

export type ScrollNamesType = ValueOf<typeof SCROLL_NAMES>;
